











import { defineComponent, onMounted, reactive, watch } from "@vue/composition-api";
import FormEmployee from "@/components/Modal/Subcomponents/FormEmployee.vue";
import { useEmployeeAPI } from "@/api-connectors/useEmployeeAPI";
import { Employee } from "@/types/apiEntities";
import { emptyObjects } from "@/types/emptyObjects";
import { toastController } from "@/composables/useToast";
import { modalController } from "@/composables/useModal";

export default defineComponent({
    name: "EditEmployee",
    components: { FormEmployee },
    props: {
        clickedEmployeeId: {
            type: Number,
            required: true
        }
    },
    setup(props, { emit }) {
        const { editEmployeeRequest, getEmployeeRequest } = useEmployeeAPI()
        const { emptyEmployee } = emptyObjects()

        const employee = reactive<Employee>(emptyEmployee())

        const updateEmployee = (updatedEmployee: Employee): void => {
            Object.assign(employee, updatedEmployee)
            // console.log(employee, updatedEmployee)
        }

        const fillMissingProperties = async (employee: Employee) => {
            if (employee.address) return;

            employee.address = { street: '', number: '', zipCode: '', city: '', country: '' }
        }

        const setEmployee = async (): Promise<void> => {
            if (props.clickedEmployeeId === 0) return;
            const apiEmployee = await getEmployeeRequest(props.clickedEmployeeId)

            if (!apiEmployee) return;

            await fillMissingProperties(apiEmployee)

            if (apiEmployee?.address?.country === 'NULL') apiEmployee.address.country = ''

            if (apiEmployee) Object.assign(employee, apiEmployee)
        }

        const checkAddressEmpty = () => {
            return !employee.address?.street &&
                !employee.address?.number &&
                !employee.address?.zipCode &&
                !employee.address?.city &&
                !employee.address?.country
        }

        const checkRequiredAddressFieldsMissing = () => {
            return !employee.address?.street ||
                !employee.address?.zipCode ||
                !employee.address?.city
        }

        const cleanRequestBody = async () => {
            const tempObject = { ...employee }
            if (checkAddressEmpty()) {
                tempObject.address = null
                console.log(tempObject)
                return tempObject;
            }

            if (checkRequiredAddressFieldsMissing()) {
                toastController.toast(`Füllen Sie alle Addressfelder aus oder lassen diese Leer.`, {
                    title: 'Fehler',
                    autoHideDelay: 5000,
                    variant: 'warning',
                    solid: true
                })
                return undefined
            }

            return tempObject;
        }

        const editEmployee = async () => {
            const cleanedRequest = await cleanRequestBody()

            if (!cleanedRequest) return;

            console.log(cleanedRequest)

            const response = await editEmployeeRequest(cleanedRequest)

            if (!response) return toastController.toast(`Der Mitarbeiter konnte nicht bearbeitet werden.`, {
                title: 'Fehler',
                autoHideDelay: 3000,
                variant: 'warning',
                solid: true
            })

            toastController.toast(`Der Mitarbeiter wurde bearbeitet`, {
                title: 'Mitarbeiter bearbeitet',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

            emit('edited')
            modalController.hide('edit-employee')
        }

        onMounted(async () => setEmployee())

        watch(props, () => setEmployee())

        return {
            employee,
            updateEmployee,
            editEmployee
        }
    }
})
