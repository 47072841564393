































import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { CompanySelection } from "@/types/selectionTypes";
import { useCompanyAPI } from "@/api-connectors/useCompanyAPI";
import { useTable } from "@/composables/useTable";
import router from "@/router";
import useGlobalStorage from "@/composables/useGlobalStorage";

export default defineComponent({
    name: "SelectionCompany",
    setup() {
        const { requestCompanies } = useCompanyAPI()
        const { fillList } = useTable()
        const { globalStore } = useGlobalStorage()

        const companies = reactive<CompanySelection[]>([])

        const changeCompany = (companyId: number) => {
            const newCompany = companies.find(company => company.id === companyId)

            if (!newCompany?.id || !newCompany?.name) return;


            globalStore.currentCompany.id = newCompany.id?.toString()
            globalStore.currentCompany.name = newCompany.name
            const currentRouteName = router.currentRoute.name
            if (typeof currentRouteName === 'string') {
                router.replace({ name: currentRouteName, params: { companyId: companyId.toString() } })
                    .catch(() => {
                    })
            }
        }

        const getCompanies = async () => {
            const response = await requestCompanies()

            if (!response?.data) return;

            fillList(companies, response.data)
        }

        const activeCompany = computed(() => {
            return Number(globalStore.currentCompany.id)
        })

        onMounted(() => getCompanies())

        return {
            companies,
            activeCompany,
            changeCompany
        }
    }
})
