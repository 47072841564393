import { useAPI } from "@/composables/useAPI";
import { AxiosResponse } from "axios";
import { Employee } from "@/types/apiEntities";

export function useEmployeeAPI() {
    const { api } = useAPI()

    const createEmployeeRequest = async (employee: Employee): Promise<AxiosResponse | undefined> => {
        return await api.post('employee/create', employee)
            .catch(() => {
                return undefined
            })
    }

    const editEmployeeRequest = async (employee: Employee): Promise<AxiosResponse | undefined> => {
        if (!employee.id) return undefined

        return await api.put('employee/update/' + employee.id, employee)
            .catch(() => {
                return undefined
            })
    }

    const getEmployeeRequest = async (employeeID: number): Promise<Employee | undefined> => {
        const response = await api.get('employee/id/' + employeeID)
            .catch(() => {
                return undefined
            })

        return response?.data;
    }

    const deleteEmployeeRequest = async (employeeID: number): Promise<AxiosResponse | undefined> => {
        return await api.delete('/employee/delete/' + employeeID)
            .catch((e) => {
                console.log(e.message)
                return undefined;
            })
    }

    const getAllEmployeeRequest = async (): Promise<AxiosResponse | undefined> => {
        return await api.get('/employee/all')
            .catch((e) => {
                console.log(e.message)
                return undefined;
            })
    }

    return {
        createEmployeeRequest,
        editEmployeeRequest,
        getEmployeeRequest,
        deleteEmployeeRequest,
        getAllEmployeeRequest
    }
}