import { Employee, RfidTag } from "@/types/apiEntities";
import { TagType } from "@/types/enums";
import { FormChargerType } from "@/types/RequestTypes";

export function emptyObjects() {
    const emptyEmployee = (): Employee => {
        return {
            personnelNumber: '',
            firstName: '',
            lastName: '',
            lr3Area: '',
            email: '',
            address: {
                street: '',
                number: '',
                zipCode: '',
                city: '',
                country: ''
            },
            company: {
                id: 0
                // address: {
                //     street: '',
                //     number: '',
                //     zipCode: '',
                //     city: ''
                // },
                // employees: [],
                // chargers: [],
                // rfidTags: [],
                // active: true,
                // name: ''
            },
            rfidTags: []
        }
    }

    const emptyRfidTag = (): RfidTag => {
        return {
            id: '',
            active: true,
            type: TagType.COMPANY,
            company: {
                id: 0
            },
            transactions: [],
            employee: {
                id: 0
            }
        }
    }

    const emptyCharger = (): FormChargerType => {
        return {
            id: '',
            alias: '',
            areaType: '',
            location: '',
            group: '',
            meterId: ''
        }
    }

    return {
        emptyEmployee,
        emptyRfidTag,
        emptyCharger
    }
}