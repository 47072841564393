








































































































import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { Employee } from "@/types/apiEntities";
import FormInput from "@/components/FormFields/FormInput.vue";
import { useCompanyAPI } from "@/api-connectors/useCompanyAPI";
import FormSelect from "@/components/FormFields/FormSelect.vue";

export default defineComponent({
    name: "FormEmployee",
    components: { FormSelect, FormInput },
    props: {
        employee: {
            type: Object as PropType<Employee>,
            required: true
        }
    },
    setup(props) {
        const { requestCompanies } = useCompanyAPI()

        let updatedEmployee = reactive<Employee>(props.employee)
        const companies = reactive<{ id: number | undefined; name: string }[]>([])

        const lr3Areas = [
            { value: '', text: 'Bitte wählen', disabled: true },
            { value: 'Isar', text: 'Isar' },
            { value: 'Main', text: 'Main' },
            { value: 'Landshut', text: 'Landshut' },
            { value: 'Lech', text: 'Lech' },
            { value: 'Donau', text: 'Donau' },
            { value: 'PSW', text: 'PSW' }
        ]

        const getCompaniesList = async () => {
            const tempCompanies = await requestCompanies()

            if (tempCompanies) {
                companies.splice(0)
                companies.push({ id: undefined, name: 'Bitte Wählen' })
                for (const company of tempCompanies.data) {
                    companies.push(company)
                }
            }
        }

        const generateMissingProperties = () => {
            if (!updatedEmployee.address) updatedEmployee.address = {
                street: '',
                number: '',
                zipCode: '',
                city: '',
                country: ''
            }
            if (!updatedEmployee.company) updatedEmployee.company = { id: 0 }
        }

        onMounted(() => {
            getCompaniesList()
            generateMissingProperties()
        })

        return {
            companies,
            updatedEmployee,
            lr3Areas
        }
    }
})
