











import { defineComponent } from "@vue/composition-api";
import SelectionCompany from "@/components/SelectionCompany.vue";
import ManagementEmployeeTable from "@/components/ManagementEmployeeTable.vue";

export default defineComponent({
    name: "ManagementEmployee",
    components: { ManagementEmployeeTable, SelectionCompany }
})
