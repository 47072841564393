











import FormEmployee from "@/components/Modal/Subcomponents/FormEmployee.vue";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { emptyObjects } from "@/types/emptyObjects";
import { Employee } from "@/types/apiEntities";
import { useEmployeeAPI } from "@/api-connectors/useEmployeeAPI";
import vueInstance from "@/main";
import router from "@/router";
import { toastController } from "@/composables/useToast";
import { modalController } from "@/composables/useModal";

export default defineComponent({
    name: "AddEmployee",
    components: { FormEmployee },
    setup(_, { emit }) {
        const { createEmployeeRequest } = useEmployeeAPI()
        const { emptyEmployee } = emptyObjects()

        const newEmployee = reactive<Employee>(emptyEmployee())

        const updateEmployee = (updatedEmployee: Employee) => {
            Object.assign(newEmployee, updatedEmployee)
        }

        const setEmployee = () => {
            Object.assign(newEmployee, emptyEmployee())
        }

        const setCompany = () => {
            newEmployee.company.id = Number(router.currentRoute.params.companyId)
        }

        const checkAddressEmpty = () => {
            return !newEmployee.address?.street &&
                !newEmployee.address?.number &&
                !newEmployee.address?.zipCode &&
                !newEmployee.address?.city &&
                !newEmployee.address?.country
        }

        const checkRequiredAddressFieldsMissing = () => {
            return !newEmployee.address?.street ||
                !newEmployee.address?.zipCode ||
                !newEmployee.address?.city
        }

        const cleanRequestBody = async () => {
            const tempObject: Employee = { ...newEmployee }
            if (checkAddressEmpty()) {
                tempObject.address = null
                return tempObject;
            }

            if (checkRequiredAddressFieldsMissing()) {
                toastController.toast(`Füllen Sie alle Addressfelder aus oder lassen diese Leer.`, {
                    title: 'Fehler',
                    autoHideDelay: 5000,
                    variant: 'warning',
                    solid: true
                })
                return undefined
            }

            return tempObject;
        }

        const createEmployee = async () => {
            const cleanedRequest = await cleanRequestBody()

            if (!cleanedRequest) return;

            const response = await createEmployeeRequest(cleanedRequest)

            if (!response) return toastController.toast(`Der Mitarbeiter konnte nicht erstellt werden.`, {
                title: 'Fehler',
                autoHideDelay: 3000,
                variant: 'warning',
                solid: true
            })

            toastController.toast(`Der Mitarbeiter wurde erstellt`, {
                title: 'Mitarbeiter erstellt',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

            emit('created')
            modalController.hide('add-employee')
        }

        onMounted(() => setEmployee())

        vueInstance.$on('bv::modal::show', (event: Object, id: string) => {
            if (id === 'add-employee') setCompany()
        })

        vueInstance.$on('bv::modal::hide', (event: Object, id: string) => {
            if (id === 'add-employee') setEmployee()
        })

        return {
            newEmployee,
            createEmployee,
            updateEmployee
        }
    }
})
