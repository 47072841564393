






























































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { TableEmployee } from "@/types/tableTypes";
import { useTable } from "@/composables/useTable";
import { modalController, useModal } from "@/composables/useModal";
import EditEmployee from "@/components/Modal/EditEmployee.vue";
import router from "@/router";
import PaginationRow from "@/components/PaginationRow.vue";
import { useEmployeeAPI } from "@/api-connectors/useEmployeeAPI";
import AddEmployee from "@/components/Modal/AddEmployee.vue";
import { toastController } from "@/composables/useToast";
import SearchbarTable from "@/components/Table/SearchbarTable.vue";

export default defineComponent({
    name: "ManagementEmployeeTable",
    components: { SearchbarTable, AddEmployee, PaginationRow, EditEmployee },
    setup() {
        const { updateTable, pageOptions } = useTable()
        const { createMsgBoxConfirm } = useModal()
        const { deleteEmployeeRequest } = useEmployeeAPI()

        const items = reactive<TableEmployee[]>([])
        const fields = [
            { key: 'personnelNumber', label: 'Personal Nr.', sortable: true },
            { key: 'firstName', label: 'Vorname', sortable: true },
            { key: 'lastName', label: 'Nachname', sortable: true },
            { key: 'lr3Area', label: 'Lr3-Bereich', sortable: true },
            { key: 'id', label: '', thClass: 'button-col' }
        ]

        const perPage = ref(10);
        const currentPage = ref(1);
        const amountRows = ref(0);
        const isBusy = ref(false)
        const clickedID = ref(0);
        const filter = ref('');
        const sortBy = ref('personnelNumber');
        const sortDesc = ref(false);

        const updateEmployeeList = async (page?: number) => {
            isBusy.value = true
            if (!page) page = currentPage.value;
            const offset = (page - 1) * perPage.value
            await updateTable({
                routePath: 'employee/company/' + router.currentRoute.params.companyId,
                targetList: items,
                targetMaxCount: amountRows,
                offset: offset,
                perPage: perPage.value,
                filter: filter.value,
            })
            isBusy.value = false
        }

        const addEmployee = () => {
            modalController.show('add-employee')
        }

        const editEmployee = (employee: TableEmployee) => {
            const { id } = employee
            // console.log(id, employee)
            clickedID.value = id ?? 0;
            modalController.show('edit-employee')
        }

        const deleteEmployee = async (id: number) => {
            const response = await deleteEmployeeRequest(id)

            if (!response) return toastController.toast('Der Mitarbeiter konnte nicht gelöscht werden.', {
                title: 'Fehler',
                variant: 'danger',
                autoHideDelay: 3000
            })

            toastController.toast('Der Mitarbeiter wurde gelöscht', {
                title: 'Mitarbeiter gelöscht',
                variant: 'success',
                autoHideDelay: 3000
            })

            await updateEmployeeList()

            // console.log('updated')
        }

        const checkDelete = (id: number) => {
            createMsgBoxConfirm({
                header: 'Löschen',
                contentText: 'Wollen Sie diesen Mitarbeiter wirklich löschen?',
                okHandler: () => deleteEmployee(id),
            })
        }

        onMounted(() => {
            isBusy.value = true
            updateEmployeeList(1)
        })

        return {
            items,
            currentPage,
            perPage,
            amountRows,
            fields,
            pageOptions,
            isBusy,
            clickedID,
            filter,
            sortBy,
            sortDesc,
            updateEmployeeList,
            editEmployee,
            checkDelete,
            addEmployee
        }
    }
})
