












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "FormSelect",
    props: {
        label: String,
        value: String,
        required: Boolean,
        options: Array
    }
})
