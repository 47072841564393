export enum TagType {
    COMPANY = 'company',
    PRIVATE = 'private',
    EXTERN = 'extern'
}

export enum UpdateType {
    DIFFERENTIAL = 'Differential',
    FULL = 'Full'
}

export enum SummaryTypesExport {
    UNIPER_ABC = 'uniper',
    UNIPER_CONSUMPTION_HYDRO = 'uniper-consumption-hydro',
    // CAR_POOL_MANAGER = 'car-pool-manager',
    // EMPLOYEE = 'employee',
    // PAYMENT = 'payment',
    // AREA_MANAGER = 'area-manager',
}


export function getSummaryTypeStrings(type: SummaryTypesExport) {
    const summaryTypeStrings = {
        [SummaryTypesExport.UNIPER_ABC]: 'Uniper ABC',
        [SummaryTypesExport.UNIPER_CONSUMPTION_HYDRO]: 'Uniper Consumption Hydro',
        // [SummaryTypesExport.CAR_POOL_MANAGER]: 'Car Pool Manager',
        // [SummaryTypesExport.EMPLOYEE]: 'Employee',
        // [SummaryTypesExport.PAYMENT]: 'Payment',
        // [SummaryTypesExport.AREA_MANAGER]: 'Area Manager',
    };
    try {
        return summaryTypeStrings[type];
    } catch (error) {
        console.log(error);
        return '';
    }
}
